<template>
  <v-card color="white" height="60px" class="white hidden-sm-and-up" flat>
    <v-bottom-nav :active.sync="bottomNav" :value="true" app color="white" class="elevation-2">
      <v-btn color="#518FF5" flat value="Home" router to="/home">
        <span>Home</span>
        <v-icon>dashboard</v-icon>
      </v-btn>

      <v-btn color="#518FF5" flat value="events" router to="/events">
        <span>Events</span>
        <v-icon>rounded_corner</v-icon>
      </v-btn>

      <v-btn color="#518FF5" flat value="team" router to="/team">
        <span>Team</span>
        <v-icon>group</v-icon>
      </v-btn>

      <!-- <v-btn color="#518FF5" flat value="devfest" router to="/devfest2020">
        <span>DevFest</span>
        <v-icon>favorite</v-icon>
      </v-btn> -->

      <!-- <v-btn color="#518FF5" flat value="about" router to="/about">
        <span>About</span>
        <v-icon>toc</v-icon>
      </v-btn> -->

      <!-- <v-btn color="#518FF5" flat value="contact" router to="/contact">
        <span>Contact</span>
        <v-icon>person</v-icon>
      </v-btn> -->
    </v-bottom-nav>
  </v-card>
</template>


<script>
export default {
  data() {
    return {
      bottomNav: "recent",
      items: [
        { title: "Home", icon: "dashboard", route: "/home" },
        { title: "Events", icon: "rounded_corner", route: "/events" },
        { title: "Team", icon: "group", route: "/team" },
        { title: "About", icon: "toc", route: "/about" }
      ]
    };
  }
};
</script>